.bm-menu-wrap {
  background-color: #625A41;
  color: #fff;
  top: 0px;
  padding: 1.5rem;
}

.bm-cross-button {
  width: 40px !important;
  height: 40px !important;
  right: 20px !important;
  top: 20px !important;
}

.bm-burger-button {
  width: 33px !important;
}

/* @media (max-width: 768px) {
  .react-multiple-carousel__arrow {
    display: none;
  }
} */